import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import * as styles from './owl-ux-eval-deck.module.scss';

import 'react-owl-carousel2/lib/styles.css'; // Allows for server-side rendering.

// NOTE: Owl doesn't play nice with Gatsby's <Img> component, so we are resorting to
// static linking the deck images.
// We've resized them on the command line using convert to max 1024x1024 to limit data size.
import deck1 from './static/deck1r.jpeg';
import deck2 from './static/deck2r.jpg';
import deck3 from './static/deck3r.jpeg';

export default class Section extends React.Component {
	constructor(props) {
		super(props);

		const options = {
			items: 1,
			nav: false,
			dots: true,
			rewind: true,
			autoplay: true,
			loop: true,
			autoplayHoverPause: true,
		};

		const events = {
			onDragged(event) {},
			onChanged(event) {},
		};

		this.owlConfig = { options, events };
	}

	render() {
		return (
			<>
				<section className={styles.wrap}>
					<div className={`${styles.content} container`}>
						<OwlCarousel ref={(el) => (this.el = el)} {...this.owlConfig}>
							<div className="team-item">
								<div className="team-item-img">
									<img src={deck1} className="" alt="" />
								</div>
								<div className="team-item-name font-second">
									<h4 className="">Customers</h4>
								</div>
								<div className="team-item-info">
									<div className="team-item-text">
										<h3 className="font-second">Customers</h3>

										<p>
											Do you know exactly what's frustrating customers when they
											use your products, systems and services? What barriers are
											preventing more efficient use? More effective use? More
											successful use?
											<br />
											<br />
											UX Research illuminates these types of issues, aka
											opportunities, so you know what to fix to make your
											customers happier and more productive.
										</p>
									</div>
								</div>
							</div>
							<div className="team-item">
								<div className="team-item-img">
									<img src={deck2} className="" alt="" />
								</div>
								<div className="team-item-name font-second">
									<h4 className="">Employees &amp; Contractors</h4>
									<span></span>
								</div>
								<div className="team-item-info">
									<div className="team-item-text">
										<h3 className="font-second">Employees &amp; Contractors</h3>

										<p>
											Not only are these other people your users too, but they
											are integral to your operation and they depend on your
											internal systems to efficiently and effectively do their
											jobs.
											<br />
											<br /> All too frequently, employees and contractors are
											saddled with yesterday’s technology, which is not only
											frustrating but requires training and wastes tremendous
											amounts of time every year across organizations.{' '}
										</p>
									</div>
								</div>
							</div>
							<div className="team-item">
								<div className="team-item-img">
									<img src={deck3} className="" alt="" />
								</div>
								<div className="team-item-name font-second">
									<h4 className="">Vendors / Suppliers</h4>
									<span></span>
								</div>
								<div className="team-item-info">
									<div className="team-item-text">
										<h3 className="font-second">Vendors / Suppliers</h3>

										<p>
											Your organization relies upon goods and services from
											vendors / suppliers that have downstream effects. As with
											your employees and contractors, these people are
											oftentimes dependent upon archaic internal systems, which
											suffer from long neglected UX too.
											<br />
											<br /> From cumbersome software interfaces to preventable
											communication mishaps, there are likely several UX issues
											causing you to experience costly business problems. Find
											out what they are by contacting us today!
										</p>
									</div>
								</div>
							</div>
						</OwlCarousel>
					</div>
				</section>
			</>
		);
	}
}

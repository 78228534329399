import './header-between-logos.scss';
import React from 'react';
import uxgGreenSlug from '../images/uxg-slug-green.png';
import uxgBlueSlug from '../images/uxg-slug.png';

export const HeaderBetweenLogos = (props) => {
	const slug = props.withBlueSlug ? uxgBlueSlug : uxgGreenSlug;
	return (
		<div className={`header-between-logos ${props.className}`}>
			<img src={slug} alt="UXG green slug" />
			<div>
				{props.header && <h1>{props.header}</h1>}
				{props.subTitle && <h3>{props.subTitle}</h3>}
			</div>
			<img src={slug} alt="UXG green slug" />
		</div>
	);
};

import React from 'react';
// import FeaturedServiceRemoteUXT from '../components/feat-srv-remote-uxt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StaticQuery, graphql } from 'gatsby';
import OwlUxEvalDeck from '../components/owl-ux-eval-deck';
import ContactWidget from '../components/contact-widget';
import Layout, { PageColors } from '../components/layout';
import Footer from '../components/footer';
import { HeaderBetweenLogos } from '../components/header-between-logos';
import * as styles from './services.module.scss';
import { ContactUxology } from '../components/contact-uxology';

import uxologyGroupLogo from '../images/uxology-group-logo.png';
import eyes from '../images/eyes.png';
import uxgSlugGreen from '../images/uxg-slug-green.png';
import uxologyGroupBlueLogo from '../images/uxology-group-blue-logo.png';
import { HeroSection } from '../components/hero-section';

export default function ServicesPage(props) {
	const render = (data) => {
		const serviceItems = (
			<>
				<div className="services-section-item col-sm-4">
					<div className="services-section-item-icon">
						<FontAwesomeIcon icon="lightbulb" />
					</div>
					<h4 className="services-item-title font-second">
						Field-Based UX Testing
					</h4>
					<div className="services-section-item-text">
						<p>
							Field-based UX Testing goes directly to where people are using
							your products, in their natural contexts, such as homes, offices,
							and retail environments.{' '}
						</p>
					</div>
				</div>

				<div className="services-section-item col-sm-4">
					<div className="services-section-item-icon">
						<FontAwesomeIcon icon="flask" />
					</div>
					<h4 className="services-item-title font-second">
						Ethnographic Observation
					</h4>
					<div className="services-section-item-text">
						<p>
							Ethnography is a social science method that has evolved for
							centuries. It's all about observing people in the context of
							interest as they go about their daily lives and business.{' '}
						</p>
					</div>
				</div>

				<div className="services-section-item col-sm-4">
					<div className="services-section-item-icon">
						<FontAwesomeIcon icon="desktop" />
					</div>
					<h4 className="services-item-title font-second">Remote UX Testing</h4>
					<div className="services-section-item-text">
						<p>
							Remote UX Testing, through web conferencing and modern research
							software, is for websites, software, and mobile applications. It’s
							cost-effective and removes geographic barriers.
						</p>
					</div>
				</div>

				<div className="services-section-item col-sm-4">
					<div className="services-section-item-icon">
						<FontAwesomeIcon icon="tachometer-alt" />
					</div>
					<h4 className="services-item-title font-second">
						Competitor UX Testing
					</h4>
					<div className="services-section-item-text">
						<p>
							Any UX Test can be expanded to include competitor products too.
							This allows direct comparison to competitors, which is valuable
							for benchmarking and roadmapping initiatives.
						</p>
					</div>
				</div>

				<div className="services-section-item col-sm-4">
					<div className="services-section-item-icon">
						<FontAwesomeIcon icon="camera" />
					</div>
					<h4 className="services-item-title font-second">
						In-Depth Interviews
					</h4>
					<div className="services-section-item-text">
						<p>
							Applying the art of knowledge elicitation techniques to interview
							stakeholders, users, and subject-matter experts is something we're
							very well versed in.{' '}
						</p>
					</div>
				</div>

				<div className="services-section-item col-sm-4">
					<div className="services-section-item-icon">
						<FontAwesomeIcon icon="cog" />
					</div>
					<h4 className="services-item-title font-second">
						Contextual Inquiry
					</h4>
					<div className="services-section-item-text">
						<p>
							As an exploratory method, contextual inquiries consist of
							observing users in a natural environment as they interact with the
							technology of interest.
						</p>
					</div>
				</div>
				<ContactUxology
					withSameSize
					page="services"
					className="services-section-item col-sm-4"
					topLabel="Questions ?"
					bottomLabel="Contact Us"
				/>
				<div className="services-section-item col-sm-4">
					<div className="services-section-item-icon">
						<FontAwesomeIcon icon="search-plus" />
					</div>
					<h4 className="services-item-title font-second">Expert Reviews</h4>
					<div className="services-section-item-text">
						<p>
							A fast and affordable method of identifying UX issues is through
							Expert Reviews, where a team of 3-5 professionals evaluate your
							prototype or product from the users' perspective.
						</p>
					</div>
				</div>
				<div className="services-section-item col-sm-4">
					<div className="services-section-item-icon">
						<FontAwesomeIcon icon="th-list" />
					</div>
					<h4 className="services-item-title font-second">Card Sorting</h4>
					<div className="services-section-item-text">
						<p>
							Card sorting and reverse card sorting (aka tree testing) studies
							inform a more intuitive information architecture by allowing users
							to categorize, name, and find key terminology.
						</p>
					</div>
				</div>

				<div className="services-section-item col-sm-4">
					<div className="services-section-item-icon">
						<FontAwesomeIcon icon="plane-departure" />
					</div>
					<h4 className="services-item-title font-second">Concept Testing</h4>
					<div className="services-section-item-text">
						<p>
							In concept testing, we gather user responses to a new product
							idea, often in early stages of development. Think of this as early
							stage qualitative A/B or multivariate testing.{' '}
						</p>
					</div>
				</div>
				<ContactUxology
					withSameSize
					page="services"
					className="services-section-item col-sm-4"
					topLabel="Questions ?"
					bottomLabel="Contact Us"
				/>
				<div className="services-section-item col-sm-4">
					<div className="services-section-item-icon">
						<FontAwesomeIcon icon="road" />
					</div>
					<h4 className="services-item-title font-second">Task Analysis</h4>
					<div className="services-section-item-text">
						<p>
							Task analysis is a method characterized by watching users perform
							tasks of interest to see how they achieve their intended goals.{' '}
						</p>
					</div>
				</div>

				<div className="services-section-item col-sm-4">
					<div className="services-section-item-icon">
						<FontAwesomeIcon icon="book" />
					</div>
					<h4 className="services-item-title font-second">Diary Studies</h4>
					<div className="services-section-item-text">
						<p>
							In diary studies, participants are compelled to share images,
							text, voice, and/or video of their thoughts and behaviors on their
							own, often based on chronological or event-based triggers.{' '}
						</p>
					</div>
				</div>

				<div className="services-section-item col-sm-4">
					<div className="services-section-item-icon">
						<FontAwesomeIcon icon="university" />
					</div>
					<h4 className="services-item-title font-second">
						Lab-Based UX Testing
					</h4>
					<div className="services-section-item-text">
						<p>
							UX testing in research laboratories is for both digital and
							physical products, so your team can observe data collection
							in-person behind a one-way mirror.{' '}
						</p>
					</div>
				</div>

				<div className="services-section-item col-sm-4">
					<div className="services-section-item-icon">
						<FontAwesomeIcon icon="smile" />
					</div>
					<h4 className="services-item-title font-second">
						Facial Expression Analysis
					</h4>
					<div className="services-section-item-text">
						<p>
							Facial Expression Analysis, thanks to cutting-edge software
							systems, enables automated classification of positive and negative
							user responses to various stimuli.{' '}
						</p>
					</div>
				</div>

				<div className="services-section-item col-sm-4">
					<div className="services-section-item-icon">
						<FontAwesomeIcon icon="chart-line" />
					</div>
					<h4 className="services-item-title font-second">
						Galvanic Skin Response
					</h4>
					<div className="services-section-item-text">
						<p>
							GSR, where advanced sensors indicate emotional responses due to
							variations in the characteristics of the skin, adds a unique and
							objective layer of data to eye tracking studies.{' '}
						</p>
					</div>
				</div>

				<div className="services-section-item col-sm-4">
					<div className="services-section-item-icon">
						<FontAwesomeIcon icon="eye" />
					</div>
					<h4 className="services-item-title font-second">Eye Tracking</h4>
					<div className="services-section-item-text">
						<p>
							By equipping participants with state-of-the-art eye tracking
							glasses, we have the ability to not only see what they see, via
							augmented reality video, but also what they look at and for how
							long; a spectacularly valuable set of data.
						</p>
					</div>
				</div>
			</>
		);

		const uxologyGroupLogos = (
			<div className={styles.uxologyGroupLogos}>
				<img src={uxologyGroupLogo} alt="UXology logo" />
				<img src={uxologyGroupLogo} alt="UXology logo" />
			</div>
		);

		return (
			<Layout page="services" location={props.location}>
				<div className={styles.servicesPage}>
					<HeroSection
						words={['REMOTE', 'LAB-BASED', 'FIELD-BASED']}
						bgColor={PageColors.services}
					>
						OUR REMOTE, LAB, AND FIELD-BASED CONSULTING SERVICES PROVIDE
						RESEARCH-BASED UX INSIGHTS TO PRODUCT TEAMS WORLDWIDE.
						<br />
					</HeroSection>

					<section className={styles.sub}>
						<img
							className={styles.headerLogo}
							src={uxologyGroupLogo}
							alt="UXology logo"
						/>
						<HeaderBetweenLogos
							className={styles.headerBetweenLogos}
							header="OUR RESEARCH SERVICES"
						/>
						<div className={styles.content}>
							<p>
								<img
									className={styles.inlineLogo}
									src={uxologyGroupLogo}
									alt="UXology logo"
								/>
								Our typical clients are mid-sized or larger companies, which
								understand the value of User Experience and want help
								identifying opportunities to improve it. We also do business
								with agencies which are servicing clients of their own and need
								a specialized research consultancy such as ours, in which case
								we are happy to work in collaboration with them and their
								clients or as a white-labeled service.{' '}
							</p>

							<p>
								<img
									className={styles.inlineLogo}
									src={uxologyGroupLogo}
									alt="UXology logo"
								/>
								Since we only do research, and no design or development, we are
								truly impartial because we're never in the position of testing
								our own products. This means you can count on us as the 100%
								neutral partner who delivers unbiased, honest, sometimes even
								hard-to-swallow insights that your product team can leverage to
								enhance your UX.
							</p>

							<p>
								<img
									className={styles.inlineLogo}
									src={uxologyGroupLogo}
									alt="UXology logo"
								/>
								In addition to the standalone offerings listed below, we also
								provide a number of proprietary service packages for clients in
								need of ongoing UX Research. From customized projects specially
								built for product teams approaching redesigns or launching new
								products, to monthly or quarterly UX Testing programs, and more,
								contact us today to discover how The UXology Group empowers
								organizations like yours with the power of invaluable UX
								insights.
							</p>
						</div>
					</section>

					<section className={styles.sub}>
						<div className="container-fluid" style={{ maxWidth: 960 }}>
							<div className="services-section-items padding-top-sm-10 row">
								{serviceItems}
							</div>
						</div>
					</section>

					<section className={styles.sub2}>
						{uxologyGroupLogos}
						<div className="content container">
							<h1>
								How Our Services Apply To Traditional &amp; Waterfall Product
								Development
							</h1>
							<GatsbyImage image={data.srv1.childImageSharp.gatsbyImageData} />
						</div>
						{uxologyGroupLogos}
						<div className="content container">
							<h1>
								How Our Services Apply To Agile &amp; Hybrid Product Development
							</h1>
							<GatsbyImage image={data.srv2.childImageSharp.gatsbyImageData} />
						</div>
						{uxologyGroupLogos}
						<div className="content container">
							<h1>Integrate Eye-Tracking Into Studies For Deeper Insights</h1>
							<div className={styles.eyes}>
								<img src={eyes} alt="Eyes" />
							</div>
							<p>
								<img
									className={styles.uxgInlineLogo}
									src={uxgSlugGreen}
									alt="UXG slug green"
								/>
								We can employ state-of-the art eye-tracking technology to many
								of our services. Virtually any UX test can be enhanced by
								equipping participants or devices with eye-tracking equipment
								that enables us to observe what they look at, in what order, and
								for how long. This adds an invaluable layer of data, which
								unveils users' involuntary thoughts and behavior, so you know
								what they see and what attracts their attention, in addition to
								what they do.
							</p>
							<GatsbyImage image={data.srv3.childImageSharp.gatsbyImageData} />
						</div>
					</section>

					{/* <FeaturedServiceRemoteUXT/> */}

					<section className={styles.sub3} style={{ marginBottom: '-2rem' }}>
						{uxologyGroupLogos}
						<div className="content container">
							<h1>
								Inward Vs. Outward Facing UX: Customers Are Not Your Only Users
							</h1>
							<p>
								<img
									className={styles.uxgInlineLogo}
									src={uxgSlugGreen}
									alt="UXG slug green"
								/>
								Remember, a user is anyone interacting with a product, system,
								or service. Upon contemplating UX, most people automatically
								think of their current and potential customers and that's fine
								because much UX work is outward facing. But it's important to
								remember that your current and potential employees, contractors,
								and vendors / suppliers are also your users. Furthermore, that
								these users are often forgotten and lost in the shuffle, when it
								comes to UX practices. Therefore, it's important to remember
								that our services apply to outward as well as inward facing
								users, depending on what aspect of your company's UX you'd like
								to improve.
							</p>

							<GatsbyImage image={data.srv4.childImageSharp.gatsbyImageData} />

							<p>
								<img
									className={styles.uxgInlineLogo}
									src={uxgSlugGreen}
									alt="UXG slug green"
								/>
								If you haven't dispatched professional UX researchers to
								independently study the interactions that all these different
								types of users have with your organization, then you're missing
								out on some excellent learning opportunities. Our consultants
								discover the unknown UX issues affecting your organization
								and/or illuminate known issues in a way that helps you build a
								business case to fix them. Let's dive into these different types
								of users a bit more.
							</p>

							<p>
								<img
									className={styles.uxgInlineLogo}
									src={uxgSlugGreen}
									alt="UXG slug green"
								/>
								How are you evaluating the UX of your customers? Meeting their
								needs and expectations, if not exceeding them, is critical to
								your survival and growth. You can't stay in business without
								them. The more you know about how to better solve their actual
								and perceived problems, the better the experience you can
								provide and that's good for business in a multitude of ways.
								You're likely assessing customer experience (CX) at a
								macro-level, perhaps through NPS scores, surveys, social media,
								analytics, feedback forms, focus groups, and other means, but
								how are you evaluating USER Experience? UX Research is more
								reliant upon direct, in-the-moment observation, than the
								aforementioned indirect methods which characterize the fields of
								customer experience and marketing today. Our more modern, and
								powerful, methods compliment all of those and illuminate
								specific opportunities to enhance the experience that customers
								have with your products, systems, and services so you know how
								to better serve them. We uncover the unknown so you're aware of
								what to fix and can chart a smarter, more profitable, and more
								expedient path forward.
							</p>

							<OwlUxEvalDeck gatsbyImageData={data} />

							<p>
								<img
									className={styles.uxgInlineLogo}
									src={uxgSlugGreen}
									alt="UXG slug green"
								/>
								Remember, UX doesn't stop at the customer. Are you also
								assessing UX for your employees, contractors, and vendors /
								suppliers? How many ‘user errors’ by these internal people
								result in sluggish processes, rework, and business problems that
								could have been prevented by offering a superior User Experience
								for them in the first place? Who pays for these problems? Your
								organization does. The stifled productivity arising from bad UX
								for your internal people isn’t something you can afford to
								ignore. From company intranets to various types of dashboards,
								information repositories, resource management systems, reporting
								tools, and clunky communication mechanisms, almost every
								organization is rife with internal UX problems.{' '}
							</p>

							<p>
								<img
									className={styles.uxgInlineLogo}
									src={uxgSlugGreen}
									alt="UXG slug green"
								/>
								The point here is to remember how broad this field is and that
								we are just as comfortable investigating inward facing UX as
								outward facing UX. They go hand in hand. Contact us today for a
								fresh and independent approach to identifying the use-related
								issues that are keeping your business from the proverbial
								next-level!
							</p>
						</div>
						<div className={styles.uxologyGroupBlueLogos}>
							<img src={uxologyGroupBlueLogo} alt="UXG slug green" />
							<img src={uxologyGroupBlueLogo} alt="UXG slug green" />
						</div>
					</section>

					<ContactWidget showSocial={true} />
					<Footer page="services" location={props.location} />
				</div>
			</Layout>
		);
	};

	return (
		<StaticQuery
			query={graphql`
				{
					srv1: file(relativePath: { regex: "/uxg-srv1.png/" }) {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
					srv2: file(relativePath: { regex: "/uxg-srv2.png/" }) {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
					srv3: file(relativePath: { regex: "/uxg-srv3.png/" }) {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
					srv4: file(relativePath: { regex: "/uxg-srv4.png/" }) {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
				}
			`}
			render={(data) => render(data)}
		/>
	);

	// deck1: file(relativePath: { regex: "/deck1.jpg/" }) {
	// 	childImageSharp {
	// 		fluid(maxWidth: 640) {
	// 			...GatsbyImageSharpFluid
	// 		}
	// 	}
	// }

	// deck2: file(relativePath: { regex: "/deck2.png/" }) {
	// 	childImageSharp {
	// 		fluid(maxWidth: 640) {
	// 			...GatsbyImageSharpFluid
	// 		}
	// 	}
	// }

	// deck3: file(relativePath: { regex: "/deck3.jpg/" }) {
	// 	childImageSharp {
	// 		fluid(maxWidth: 640) {
	// 			...GatsbyImageSharpFluid
	// 		}
	// 	}
	// }
}

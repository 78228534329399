// extracted by mini-css-extract-plugin
export var servicesPage = "services-module--servicesPage--3tkn6";
export var headerLogo = "services-module--headerLogo--17ttY";
export var inlineLogo = "services-module--inlineLogo--3MBZf";
export var headerBetweenLogos = "services-module--headerBetweenLogos--3jTyH";
export var sub = "services-module--sub--2iaAO";
export var sub2 = "services-module--sub2--30yhx";
export var sub3 = "services-module--sub3--3C8si";
export var overviewHeader = "services-module--overviewHeader--2r8bt";
export var uxologyGroupLogos = "services-module--uxologyGroupLogos--TEICg";
export var uxologyGroupBlueLogos = "services-module--uxologyGroupBlueLogos--dQTVU";
export var eyes = "services-module--eyes--cjPR8";
export var uxgInlineLogo = "services-module--uxgInlineLogo--7lF9C";